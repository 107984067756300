import { MapOptions } from './Maps/Map.js';
import { MapRenderTargetOptions } from './Renderer/MapRenderTarget.js';
import { RendererOptions } from './Renderer/Renderer.js';
import { Coordinate } from './Geo/Coordinate.js';
import { PinProperties } from './Maps/PinProperties.js';
import { PinClustererOptions } from './PinClusterer/PinClusterer.js';
import { smoothScroll } from './Util/SmoothScroll.js';
import { Unit } from './Geo/constants.js';

const STORAGE_KEY_HOVERED_RESULT = 'HOVERED_RESULT_KEY';
const STORAGE_KEY_SELECTED_RESULT = 'SELECTEDED_RESULT_KEY';

// TODO: If this comment is still here, ask Jason Ronkin about this file!

const mapPin = ({
  backgroundColor = '#00759e',
  strokeColor = 'black',
  entity = {},
  index = '',
  labelColor = 'white',
  width = '24', // 24 for selected, 33
  height= '28', // 28 for selected, 39
} = {}) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 28">
      <g fill="none" fill-rule="evenodd">
        <path fill="${backgroundColor}" fill-rule="nonzero" stroke="${strokeColor}" d="m0.96324,11.85524c0,5.563 7.196,12.418 9.947,14.836c0.6,0.527 1.509,0.53 2.112,0.005c2.756,-2.396 9.941,-9.185 9.941,-14.841c0,-5.947 -4.925,-10.767 -11,-10.767s-11,4.82 -11,10.767"/>
      </g>
    </svg>`)}`;
};

const clusterPin = ({
  backgroundColor = '#00759e',
  strokeColor = 'black',
  pinCount = '',
  labelColor = 'white'
} = {}) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <circle fill="${backgroundColor}" fill-rule="nonzero" stroke="${strokeColor}" cx="12" cy="12" r="11"/>
        <text fill="${labelColor}" font-family="Arial-BoldMT,Arial" font-size="12" font-weight="bold">
          <tspan x="50%" y="16" text-anchor="middle">${pinCount}</tspan>
        </text>
      </g>
    </svg>`)}`;
};

const clusterPinHovered = (options) => clusterPin({ backgroundColor: 'green', ...options });

class CustomMap extends ANSWERS.Component {
  static defaultTemplateName() {
    return "CustomMapTemplate";
  }

  static areDuplicateNamesAllowed() {
    return false;
  }

  static get type() {
    return "CustomMap";
  }

  constructor(config, systemConfig) {
    super(config, systemConfig);
    this.moduleId = "vertical-results";
    this._verticalsConfig = config.verticalPages || [];
    this.mapOptions = config.mapOptions;
    this.onPinSelect = config.onPinSelect;

    this.initialData = null;
    this.renderReady = false;

    const mapWrapper = (this.mapOptions.container instanceof HTMLElement) ? this.mapOptions.container : document.querySelector(this.mapOptions.container);
    const renderer = new RendererOptions().build()
    const hideOffscreenPins = false;
    const iconsForEntity = (entity, index) => ({
      default: (this.mapOptions.pin || {}).icon || mapPin({ entity, index, ...(this.mapOptions.pin || {}) }),
      hovered: (this.mapOptions.pinHovered || {}).icon || mapPin({ entity, index, ...(this.mapOptions.pinHovered || {}) }),
      selected: (this.mapOptions.pinSelected || {}).icon || mapPin({ entity, index, ...(this.mapOptions.pinSelected || {}) })
    });
    const pinBuilder = (pinOptions, entity, index) => {
      Object.entries(iconsForEntity(entity, index))
        .forEach(([name, icon]) => pinOptions.withIcon(name, icon));
      pinOptions.withHideOffscreen(hideOffscreenPins);
      return this.buildPin(pinOptions, entity, index);
    };
    const mapRenderCallback = (data, map, pins) => {
      if ((config.pageSettings || {}).layout == 'dill') {
        const locatorEl = document.getElementById('js-locator');
        const mobileToggles = locatorEl.querySelector('.js-locator-mobiletoggles');
        const listToggle = mobileToggles.querySelector('.js-locator-listToggle');

        let showToggles = false;

        if (!data.detailEntity) {
          if (data.response && data.response.entities && data.response.entities.length) {
            showToggles = true;
          }
          locatorEl.classList.remove('Locator--detailShown');
          this.core.globalStorage.delete('SearchThisArea');
        } else {
          locatorEl.classList.add('Locator--detailShown');
          locatorEl.classList.remove('Locator--listShown');

          // const closeButton = locatorEl.querySelector('.js-locator-detailClose');
          // closeButton.addEventListener('click', async () => {
          //   currentData.detailEntity = null;
          //   await elementRenderTarget.render(currentData);
          // });
        }

        if (showToggles) {
          mobileToggles.classList.add('Locator-mobileToggles--shown');
          if (!listToggle.dataset.listened) {
            listToggle.dataset.listened = 'true';
            listToggle.addEventListener('click', () => {
              locatorEl.classList.toggle('Locator--listShown');
              locatorEl.classList.remove('Locator--detailShown');
            });
          }
        } else {
          mobileToggles.classList.remove('Locator-mobileToggles--shown');
        }
      }
    };

    this.renderer = renderer;
    this.hoveredPinId = null;
    this.selectedPinId = null;

    let mapProviderPromise;
    switch ((this.mapOptions.mapProvider || '').toLowerCase()) {
      case 'baidu':
        mapProviderPromise = import('./Maps/Providers/Baidu.js').then(({ BaiduMaps }) => BaiduMaps);
        break;
      case 'bing':
        mapProviderPromise = import('./Maps/Providers/Bing.js').then(({ BingMaps }) => BingMaps);
        break;
      case 'google':
        mapProviderPromise = import('./Maps/Providers/Google.js').then(({ GoogleMaps }) => GoogleMaps);
        break;
      case 'leaflet':
        mapProviderPromise = import('./Maps/Providers/MapQuest.js').then(({ LeafletMaps }) => LeafletMaps);
        break;
      case 'mapquest':
        mapProviderPromise = import('./Maps/Providers/MapQuest.js').then(({ MapQuestMaps }) => MapQuestMaps);
        break;
      default:
        mapProviderPromise = import('./Maps/Providers/Mapbox.js').then(({ MapboxMaps }) => MapboxMaps);
    }

    const mapPadding = this.mapOptions.padding || {
      top: window.innerWidth <= 991 ? 150 : 50,
      right: 50,
      bottom: 50,
      left: 50
    };

    if ((config.pageSettings || {}).layout == 'dill') {
      const leftPadding = mapPadding.left;
      const locatorContent = document.querySelector('.js-locator-contentWrap');

      mapPadding.left = () => leftPadding + (locatorContent && window.innerWidth > 991 ? locatorContent.offsetWidth : 0);
    }

    mapProviderPromise.then(mapProvider => mapProvider.load(this.mapOptions.apiKey, {
      client: this.mapOptions.clientId,
      language: this.mapOptions.locale
    }).then(() => {
      const map = new MapOptions()
        .withDefaultCenter(this.mapOptions.defaultPosition || new Coordinate(37.0902, -95.7129))
        .withDefaultZoom('zoom' in this.mapOptions ? this.mapOptions.zoom : 14)
        .withWrapper(mapWrapper)
        .withProvider(mapProvider)
        .withProviderOptions(this.mapOptions.providerOptions || {})
        .withPadding(mapPadding)
        .build();

      this.map = map;
      // TODO google specific
      google.maps.event.addListener(map._map.map, 'dragend', () => {
        const locatorEl = document.getElementById('js-locator');
        locatorEl.classList.add('Locator--showSearchThisArea');
      });

      const mapRenderTargetOptions = new MapRenderTargetOptions()
        .withMap(map)
        .withOnPostRender((data, map) => mapRenderCallback(data, map, mapRenderTarget.getPins()))
        .withPinBuilder(pinBuilder)

      if (this.mapOptions.collapsePins && typeof this.mapOptions.pinClusters == 'object') {
        const clustererOptions = new PinClustererOptions()
          .withIconTemplate('default', config =>
            (this.mapOptions.pinClusters.pin || {}).icon || clusterPin({ ...config, ...(this.mapOptions.pinClusters.pin || {}) })
          )
          .withIconTemplate('hovered', config =>
            (this.mapOptions.pinClusters.pinHovered || {}).icon || clusterPinHovered({ ...config, ...(this.mapOptions.pinClusters.pinHovered || {}) })
          )
          .withPropertiesForStatus(status => {
            const properties = new PinProperties()
              .setIcon(status.hovered || status.focused ? 'hovered' : 'default');

            const pinConfig = { ...(this.mapOptions.pinClusters.pin || {}) };

            if (status.hovered || status.focused) {
              Object.assign(pinConfig, (this.mapOptions.pinClusters.pinHovered || {}));
            }

            if ('anchorX' in pinConfig) {
              properties.setAnchorX(pinConfig.anchorX);
            }
            if ('anchorY' in pinConfig) {
              properties.setAnchorY(pinConfig.anchorY);
            }
            if ('height' in pinConfig) {
              properties.setHeight(pinConfig.height);
            }
            if ('width' in pinConfig) {
              properties.setWidth(pinConfig.width);
            }

            return properties;
          });

        if ('minSize' in this.mapOptions.pinClusters) {
          clustererOptions.withMinClusterSize(this.mapOptions.pinClusters.minSize);
        }
        if ('radius' in this.mapOptions.pinClusters) {
          clustererOptions.withClusterRadius(this.mapOptions.pinClusters.radius);
        }
        if ('zoomAnimated' in this.mapOptions.pinClusters) {
          clustererOptions.withClusterZoomAnimated(this.mapOptions.pinClusters.zoomAnimated);
        }
        if ('zoomMax' in this.mapOptions.pinClusters) {
          clustererOptions.withClusterZoomMax(this.mapOptions.pinClusters.zoomMax);
        }

        mapRenderTargetOptions.withPinClusterer(clustererOptions.build());
      }

      const mapRenderTarget = mapRenderTargetOptions.build();

      renderer.register(mapRenderTarget);
      this.renderReady = true;
      if (this.initialData) {
        this.renderer.render(this.initialData);
      }
      window.mappp = this.map;

      document.getElementById('js-searchThisArea').addEventListener('click', () => {
        const locatorEl = document.getElementById('js-locator');
        locatorEl.classList.remove('Locator--showSearchThisArea');
        console.log(this.map.getVisibleRadius());
        const object = {
          lat: this.map.getVisibleCenter().latitude,
          lng: this.map.getVisibleCenter().longitude,
          radius: this.map.getVisibleRadius(),
        }
        const { lat, lng, radius } = object;
        const filterNode = ANSWERS.FilterNodeFactory.from({
          filter: {
            'builtin.location': {
              '$near': { lat, lng, radius }
            }
          },
          remove: () => this.core.clearStaticFilterNode('SearchThisArea')
        });
        this.core.setStaticFilterNodes('SearchThisArea', filterNode);
        this.core.globalStorage.set('SearchThisArea', true);
        this.core.verticalSearch('locations', {
          setQueryParams: true,
          resetPagination: true,
          useFacets: true
        });
      });

      document.getElementById('js-backButton').addEventListener('click', () => {
        const prevZoomCenter = this.map.zoomHistory.pop();
        if (!prevZoomCenter) {
          console.error('Trying to go back to undetermined state');
          return;
        }
        this.map.setZoomCenter(prevZoomCenter.zoom, prevZoomCenter.center);
        this.updateBackButton();
        const locatorEl = document.getElementById('js-locator');
        locatorEl.classList.remove('Locator--detailShown');
      });

      this.core.globalStorage.on('update', STORAGE_KEY_HOVERED_RESULT, id => {
        if (id != this.hoveredPinId) {
          const pins = mapRenderTarget.getPins();

          if (this.hoveredPinId && pins[this.hoveredPinId]) {
            pins[this.hoveredPinId].setStatus({ hovered: false });
            this.hoveredPinId = null;
          }

          if (id && pins[id]) {
            pins[id].setStatus({ hovered: true });
            this.hoveredPinId = id;
          }
        }
      });
      this.core.globalStorage.on('update', STORAGE_KEY_SELECTED_RESULT, id => {
        if (id != this.selectedPinId) {
          const pins = mapRenderTarget.getPins();

          if (this.selectedPinId && pins[this.selectedPinId]) {
            pins[this.selectedPinId].setStatus({ selected: false });
            this.selectedPinId = null;
          }

          if (id && pins[id]) {
            pins[id].setStatus({ selected: true });
            this.selectedPinId = id;

            //collapsibleFiltersInteractions.collapseFilters();
            if (this.onPinSelect) {
              this.onPinSelect();
            }

            if (!this.map.coordinateIsInVisibleBounds(pins[id].getCoordinate())) {
              this.map.setCenterWithPadding(pins[id].getCoordinate(), true);
            }
          }
        }
      });
    }));
  }

  buildPin(pinOptions, entity, index) {
    const pin = pinOptions
      .withCoordinate(new Coordinate(entity.profile.yextDisplayCoordinate))
      .withPropertiesForStatus(status => {
        const properties = new PinProperties()
          .setIcon(status.selected ? 'selected' : ((status.hovered || status.focused) ? 'hovered' : 'default'))
          .setSRText(index)
          .setZIndex(status.selected ? 1 : ((status.hovered || status.focused) ? 2 : 0));

        const pinConfig = { ...(this.mapOptions.pin || {}) };

        properties.setWidth('24');
        properties.setHeight('28');

        if (status.hovered || status.focused) {
          Object.assign(pinConfig, (this.mapOptions.pinHovered || {}));
        }
        if (status.selected) {
          Object.assign(pinConfig, (this.mapOptions.pinSelected || {}));
        }

        if ('anchorX' in pinConfig) {
          properties.setAnchorX(pinConfig.anchorX);
        }
        if ('anchorY' in pinConfig) {
          properties.setAnchorY(pinConfig.anchorY);
        }
        if ('height' in pinConfig) {
          properties.setHeight(pinConfig.height);
        }
        if ('width' in pinConfig) {
          properties.setWidth(pinConfig.width);
        }

        return properties;
      })
      .build();

    const id = 'js-yl-' + entity.profile.meta.id;
    this.core.globalStorage.on('update', 'card-click', (data) => {
      const cardIndex = data.index;
      if (cardIndex + 1 === index) {
        this.core.globalStorage.set(STORAGE_KEY_SELECTED_RESULT, id);
      }
    });

    pin.setClickHandler(() => {
      const locatorEl = document.getElementById('js-locator');
      const yextPageContentEl = document.querySelector('.YxtPage-content');

      this.core.globalStorage.set(STORAGE_KEY_SELECTED_RESULT, id)
      const selector = `.yxt-Card[data-opts='{ "_index": ${index - 1} }']`;
      const card = document.querySelector(selector);
      const mediaQuery = window.matchMedia("(max-width: 991px)");

      document.querySelectorAll('.yxt-Card--pinClicked').forEach((el) => {
        el.classList.remove('yxt-Card--pinClicked');
      });

      card.classList.remove('yxt-Card--pinClicked');
      card.classList.add('yxt-Card--pinClicked');

      if (mediaQuery.matches) {
        const cardCopy = card.cloneNode(true);
        locatorEl.appendChild(cardCopy);
        cardCopy.querySelectorAll('.js-HitchhikerLocationStandard-exit').forEach((el) => {
          el.addEventListener('click', () => {
            card.classList.remove('yxt-Card--pinClicked');
            cardCopy.remove();
            document.getElementById('js-locator').classList.remove('Locator--detailShown');
            yextPageContentEl.classList.remove('YxtPage-content--detailShown');
          });
        });

        locatorEl.classList.add('Locator--detailShown');
        yextPageContentEl.classList.add('YxtPage-content--detailShown');
      } else {
        this.scrollToResult(card);
      }
    });
    pin.setHoverHandler(hovered => this.core.globalStorage.set(STORAGE_KEY_HOVERED_RESULT, hovered ? id : null));
    return pin;
  }

/**
 * Scroll the result list to show the given element
 * @memberof CobaltAce
 * @inner
 * @param {HTMLElement} targetEl The result card to scroll to
 */
scrollToResult(targetEl) {
  const isIE11 = !!(window.MSInputMethodContext && document.documentMode);
  const stickyHeight = 0;

  const header = document.querySelector('header');
  const headerHeight = header ? header.offsetHeight : 0;

  const container = document.querySelector('.Locator-resultsWrapper');
  const elTop = targetEl.offsetTop - (container.scrollTop + container.offsetTop);
  const elBottom = elTop + targetEl.offsetHeight;
  const isScrolledIntoView = elTop >= stickyHeight && elBottom <= container.offsetHeight - headerHeight;

  window.scroll = (x) => smoothScroll(container, x, 400);
  if (!isScrolledIntoView) {
    smoothScroll(container, elTop - stickyHeight, 400);
  }
}

/**
 * Updates Map to show/hide Back button
 */
updateBackButton () {
  document.getElementById('js-locator').classList.remove('Locator--historyAvailable');
  if (this.map.zoomHistory.length > 0) {
    document.getElementById('js-locator').classList.add('Locator--historyAvailable');
  }
}

  setState(data) {
    if (data.searchState === 'search-loading') {
      return;
    }
    const universalData = (data.map ? (data.map.mapMarkers || []) : []).map(marker => ({
      profile: {
        ...marker.item,
        meta: {
          accountId: '',
          countryCode: marker.item.address.countryCode,
          entityType: marker.item.type,
          folderId: '',
          id: marker.item.id,
          labels: '',
          language: '',
          schemaTypes: '',
          timestamp: '',
          uid: '',
          utcOffsets: '',
          yextId: marker.item.id,
        }
      }
    }));
    const locatorEl = document.getElementById('js-locator');
    if (ANSWERS.components.getComponentNamesForComponentTypes(['AlternativeVerticals']).includes('AlternativeVerticals--resultsHeader') && document.querySelector('.js-answersNoResults > .yxt-AlternativeVerticals')) {
      ANSWERS.removeComponent('AlternativeVerticals--resultsHeader');
    }
    if (data.resultsContext === 'no-results') {
      locatorEl.classList.add('Locator--noResults');

      const hasResults = data.results;
      this._displayAllResults = true; // TODO hardcoded
      ANSWERS.addComponent('AlternativeVerticals', {
        container: '.js-answersNoResults',
        verticalsConfig: this._verticalsConfig,
        baseUniversalUrl: this.getBaseUniversalUrl(),
        isShowingResults: this._displayAllResults && hasResults,
        name: 'AlternativeVerticals--resultsHeader',
      });
    } else {
      locatorEl.classList.remove('Locator--noResults');
    }
    const verticalData = (data.results || []).map(ent => ({
      profile: {
        ...ent._raw,
        meta: {
          accountId: '',
          countryCode: ent._raw.address.countryCode,
          entityType: ent._raw.type,
          folderId: '',
          id: ent.id,
          labels: '',
          language: '',
          schemaTypes: '',
          timestamp: '',
          uid: '',
          utcOffsets: '',
          yextId: ent.id,
        },
      }
    }));
    const fromSearchThisArea = this.core.globalStorage.getState('SearchThisArea');
    const liveAPIFormattted = {
      response: {
        entities: verticalData.length ? verticalData : universalData,
      },
      updateZoom: !fromSearchThisArea
    }
    if (this.renderReady) {
      this.renderer.render(liveAPIFormattted);
    } else {
      this.initialData = liveAPIFormattted;
    }
  }

  getBaseUniversalUrl () {
    const universalConfig = this._verticalsConfig.find(config => !config.verticalKey) || {};
    return universalConfig.url;
  }
}

window.CustomMap = CustomMap;
export { CustomMap };
